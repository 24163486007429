class FSAPI {
    constructor() {
        this.rootDir = null; // root on local machine FS API
        this.currentDir = null;
        this.stack = [];
    }

    init(root) {
        this.rootDir = root;
        this.currentDir = root;
    }

    async cd(dir) {
        if (typeof dir === 'string') {
            for await (const entry of this.currentDir.values()) {
                if (entry.kind === "directory" && entry.name == dir) {
                    this.stack.push(this.currentDir);
                    this.currentDir = entry;
                    //console.log("CD return true 1");
                    //console.log(this.stack);
                    return true;
                }
            }
            //console.log("CD return false");
            //console.log(this.stack);
            return false;
        }
        else {
            this.stack.push(this.currentDir);
            this.currentDir = dir;
            //console.log("CD return true 2");
            //console.log(this.stack);
            return true;
        }
    }

    home() {
        this.currentDir = this.rootDir;
        this.stack = [];
    }

    up() {
        if (this.stack.length > 0) {
            this.currentDir = this.stack.pop();
            return true;
        }
        else return false;
    }

    async getFolder(root, name) {
        for await (const entry of root.values()) {
            if (entry.kind === "directory" && entry.name == name)
                return entry;
        }
        return null;
    }

    async getFile(root, name) {
        // if file have path..
        var parts = name.split('/');
        var proot = root;
        for (var i = 0; i < parts.length - 1; i++) {
            for await (const entry of proot.values()) {
                if (entry.kind === "directory" && entry.name == parts[i]) {
                    proot = entry;
                    break;
                }
            }
        }

        for await (const entry of proot.values()) {
            if (entry.kind === "file" && entry.name == parts[parts.length - 1])
                return await entry.getFile();
        }
        console.log("cant find file " + name);
        return null;
    }

    async fileExists(root, fname) {
        for await (const entry of root.values()) {
            if (entry.kind === "file" && entry.name == fname)
                return true;
        }
        return false;
    }

    async collectFiles(path) {
        var root = null;
        if (path === undefined) root = this.currentDir;
        else root = await this.getDirFromPath(path);
        var dest = [];
        for await (const entry of root.values()) {
            if (entry.kind === "file") {
                const file = await entry.getFile();
                dest.push({
                    selected: false,
                    id: dest.length,
                    type: 2,
                    name: file.name,
                    size: file.size,
                    h: file
                });
            }
            if (entry.kind === "directory") {
                dest.push({
                    selected: false,
                    id: dest.length,
                    type: 1,
                    name: entry.name,
                    size: 0,
                    h: entry
                });
            }
        }
        dest.sort((p1, p2) => {
            if (p1.type < p2.type) return -1;
            else if (p2.type < p1.type) return 1;
            else if (p1.name < p2.name) return -1;
            else if (p2.name < p1.name) return 1;
            else return 0;
        })
        //console.log("Collect files... return");
        //console.log(dest);
        return dest;
    }

    async collectLocalFilesRec(rootName, dirHandle, out) {
        var cd = rootName + dirHandle.name;
        out.push({ type: 1, name: cd });
        for await (const entry of dirHandle.values()) {
            if (entry.kind === "file") {
                const file = await entry.getFile();
                out.push({
                    type: 2,
                    src: file,
                    name: cd + "/" + file.name
                });
            }
            else if (entry.kind === "directory") {
                await this.collectLocalFilesRec(cd + "/", entry, out);
            }
        }
    }

    getCurrentFolderName() {
        if (this.currentDir != null)
            return this.currentDir.name;
        else
            return "";
    }

    getCurrentPath() {
        var path = "";
        for (var i = 1; i < this.stack.length; i++)
            path = path + this.stack[i].name + "/";

        if (this.currentDir != this.rootDir)
            path = path + this.getCurrentFolderName() + "/";

        return path;
    }

    async goto(path) {
        var parts = path.split('/');
        var root = this.rootDir;
        for (var i = 0; i < parts.length - 1; i++)
            root = await this.cd(parts[i]);

        return root;
    }

    async getDirFromPath(path) {
        //console.log("getDirFromPath:" + path);
        var parts = path.split('/');
        var root = this.rootDir;
        if (parts[0] == '')
            return root;
        for (var i = 0; i < parts.length - 1; i++) {
            for await (const entry of root.values()) {
                if (entry.kind === "directory" && entry.name == parts[i]) {
                    root = entry;
                    break;
                }
            }
        }
        return root;
    }

    async getRelativeDirFromPath(start, path) {
        var parts = path.split('/');
        var root = start;
        if (root == null)
            root = this.rootDir;

        for (var i = 0; i < parts.length - 1; i++) {
            for await (const entry of root.values()) {
                if (entry.kind === "directory" && entry.name == parts[i]) {
                    root = entry;
                    break;
                }
            }
        }

        return root;
    }

    getFilePath(fname) {
        var parts = fname.split('/');
        if (parts > 1) {
            delete parts[parts.length - 1];
            var path = parts.join('/');
            return path;
        }
        else return "";
    }

    async createFolder(start, path) {
        var parts = path.split('/');
        var root = start;
        if (root == null)
            root = this.rootDir;
        for (var i = 0; i < parts.length; i++) {
            root = await root.getDirectoryHandle(parts[i], { create: true });
        }

        return root;
    }

    async createFolderAndFile(start, fullfn) {
        //console.log("createFolderAndFile");
        // console.log(start);
        // console.log(fullfn);

        var parts = fullfn.split('/');

        var root = start;
        if (root == null)
            root = this.rootDir;
        for (var i = 0; i < parts.length - 1; i++) {
            root = await root.getDirectoryHandle(parts[i], { create: true });
        }
        var file = await root.getFileHandle(parts[parts.length - 1], { create: true });
        return file;
    }


}

export default FSAPI;

