<template>
  <div>
      <CopyDlg ref="copydlg" />
      <HelpDlg ref="helpdlg" />
      <div v-if="!inRoom" style="margin-top:100px;">
        <input v-model="room_name" placeholder="Enter room name"/> 
        <button @click="onEnterRoom">Enter room</button>
        <button @click="onHelp">?</button>
      </div>
      <MDBContainer v-else class="mycont">
        <MDBRow class="mb-3 bg-success text-white"> 
          <MDBCol> <strong>Room: {{ room_name }} </strong></MDBCol> 
          <MDBCol class="float-end"><button @click="onHelp"> ?</button> </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol class="col-md-6">
            <MDBRow class="mb-2 text-start">
              <MDBCol class="col-md-2"><h4 class="align-bottom">Local</h4></MDBCol>
              <MDBCol class="col-md-10">
                <FileToolbar 
                :local="true"
                @share="onLocalShare"
                @goUp="onLocalGoUp"
                @goHome="onLocalGoHome"
                @goReload="onLocalReload"
                @upload="onUpload"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <FileTable class="fillvertical" :items="localUserItems" @select="onLocalSelect" @dblclick="onLocalDblClick" />
            </MDBRow>
          </MDBCol>
          <MDBCol class="col-md-6">
            <MDBRow class="mb-2 text-start">
              <MDBCol class="col-md-2"><h4 class="align-bottom">Remote</h4></MDBCol>
              <MDBCol class="col-md-10">
                <FileToolbar 
                  @goUp="GoUpOnRemoteSide"
                  @goHome="GoHomeOnRemoteSide"
                  @goReload="GoReloadRemoteSide"
                  @download="DownloadFromRemote"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <FileTable class="fillvertical" :items="remoteUserItems" @select="onRemoteSelect" @dblclick="onRemoteDblClick"/>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <ProgressTable class="progresspanel" :items="filesInProgress" :ritems="filesReceiving" />
        </MDBRow>
      </MDBContainer>
  </div>
</template>

<script>

// import axios from "axios";
import { markRaw, defineAsyncComponent } from "vue";
import socketio from "socket.io-client";
//import VirtualList from 'vue3-virtual-scroll-list';
import { MDBContainer, MDBRow, MDBCol }  from "mdb-vue-ui-kit";
import FileToolbar from "./FileToolbar.vue";
import FileTable from "./FileTable.vue";
import ProgressTable from "./ProgressTable.vue";
import CopyDlg from "./CopyDlg.vue"
import HelpDlg from "./HelpDlg.vue"
import UTILS from "./utils.js";
import FSAPI from "./fsapi.js"

var STUN = {
  'url': 'stun:stun.l.google.com:19302',
};

var TURN = {
  url: 'turn:homeo@turn.bistri.com:80',
  credential: 'homeo'
};

var iceServers =
{
  iceServers: [STUN]
};

// eslint-disable-next-line no-unused-vars
var lc = null;
var cdc = null;
var fdc = null;
//var rc= null;
//var dcc= null;

export default {
  name: "RTCComponent",
  components: {
    FileToolbar,
    FileTable,
    ProgressTable,
    MDBContainer,
    MDBRow,
    MDBCol,
    CopyDlg,
    HelpDlg
  },
  data() {
    return {
      room_name: "",
      text_data: "",
      text_to_send_data: "",
      message: "",
      user: "",
      time: "",
      socket: {},
      inRoom: false,
      fajl: {
        name: "",
        data: []
      },

      connReady: false,

      itemComponent: markRaw(defineAsyncComponent(() => import('./FileItem.vue'))),
      localDir: null, // root on local machine FS API

      localUserFS: new FSAPI(),
      localUserItems: [], // files in current folder
      localUserRemoteDir:"",

      remoteUserFS: new FSAPI(),
      remoteUserItems: [], // remote file list

      filesInProgress: [],
      fileFd: 1,
      currentFileWriter: {},
      filesReceiving: {},

    }
  },
  mounted() {
    UTILS.init(this.cmdHandler, this.fileHandler);
    window.addEventListener('unload', this.onPageUnload);
  },
  beforeDestroy() { 
    window.removeEventListener('unload', this.onPageUnload);
  },
  methods: {
    async onPageUnload(e) {
      //alert("unload");
      for (var i = 0; i < this.currentFileWriter.length; i++) {
        if (this.currentFileWriter[i] != undefined && this.currentFileWriter[i].fd != null) {
          await this.currentFileWriter[i].fd.close();
        }
      }
    },
    async cmdHandler(cmd, params) {
      if (cmd == 'list') {
        var refresh = params.refresh;
        if (refresh) {
          this.localUserRemoteDir = params.dir;
          this.remoteUserItems = params.files;
        }
        else {
          if (this.localUserRemoteDir == params.dir)
            this.remoteUserItems = params.files;
        }
      }
      else if (cmd == 'cd') {
        //console.log("CD");
        //console.log(params);
        this.remoteUserCD(params.name, params.id);
      }
      else if (cmd == 'dir') {
        //console.log("DIR");
        //console.log(params);
        var dir = params.dir;
        this.remoteUserDir(dir);
      }
      else if (cmd == "copyFinished") {
        //console.log("copyFinished");
        //console.log(params);
        var dir = params["dir"];
        this.refreshDir(dir);
      }
      else if (cmd == 'up') {
        //console.log("UP");
        //console.log(params);
        this.remoteUserGoUp();
      }
      else if (cmd == 'home') {
        //console.log("HOME");
        //console.log(params);
        this.remoteUserGoHome();
      }
      else if (cmd == "resume") {
        //console.log("RESUME");
        //console.log(params);
        var localDir = params["localDir"]
        var dir = params["dir"]
        var files = params["files"];
        await this.handleResume(localDir, dir, files);
      }
      else if (cmd == "info") {
        //console.log("INFO");
        //console.log(params);
        var localDir = params["localDir"]
        var dir = params["dir"]
        var files = params["files"];
        var copyop = params["copyop"];
        await this.handleInfo(localDir, dir, files, copyop);
      }
      else if (cmd == "infoResult") {
        //console.log("INFO RESULT");
        //console.log(params);
        var localDir = params["localDir"]
        var dir = params["dir"]
        var files = params["files"];
        var copyop = params["copyop"];
        await this.handleInfoResult(localDir, files, dir, copyop);
      }
      else if (cmd == "resumeCopy") {
        //console.log("RESUME COPY");
        //console.log(params);
        var dir = params["dir"]
        var localDir = params["localDir"]
        var files = params["files"];
        await this.resumeCopy(localDir, files, dir);
      }
      else if (cmd == "requestCopy") {
        //console.log("REQUEST COPY");

        //console.log(params);
        var dir = params["dir"];
        var localDir = params["localDir"];
        var files = params["files"];
        var copyop = params["copyop"];
        await this.requestCopy(localDir, files, dir, copyop);
      }
      else if (cmd == "openw") {
        //console.log("OPENW");
        //console.log(params);
        var destDir = params.destDir;
        var fname = params.fname;
        var copyop = params.copyop;
        var offset = params.offset;
        var fd = params.fd;
        var fsize = params.fsize;
        await this.openw(destDir, fd, fname, copyop, offset, fsize);
      }
      else if (cmd == "close") {
        //console.log("CLOSE");
        //console.log(params);
        var destDir = params.destDir;
        var fname = params.fname;
        var fd = params.fd;
        await this.close(destDir, fd, fname);
      }
      else if (cmd == "mkdir") {
        //console.log("MKDIR");
        //console.log(params);
        var destDir = params;
        await this.mkdir(destDir);
      }
    },
    async mkdir(destDir) {
      await this.localUserFS.createFolder(null, destDir);
    },

    async fileHandler(fd, data) {
      //console.log(`write to file ${fd}: bytes = ` + data.length);
      //console.log(data);
      if (this.currentFileWriter[fd] != undefined && this.currentFileWriter[fd].fd != null) {
        for (var i = 0; i < this.currentFileWriter[fd].buffer.length; i++)
          this.currentFileWriter[fd].fd.write(this.currentFileWriter[fd].buffer[i]);
        this.currentFileWriter[fd].buffer = [];

        this.currentFileWriter[fd].fd.write(data);
      }
      else {
        //console.log(`file with fd ${fd} not exists!`);
        if (this.currentFileWriter[fd] === undefined) {
          this.currentFileWriter[fd] = {
            fd: null,
            buffer: [],
            xxx: false
          }
        }
        this.currentFileWriter[fd].buffer.push(data);
      }
    },
    async close(destDir, fd, fname) {
      //console.log("close file " + fd + " / " + fname);
      if (this.currentFileWriter[fd] != undefined) {
        if (this.currentFileWriter[fd].fd != null) {
          // flush buffers, if any
          for (var i = 0; i < this.currentFileWriter[fd].buffer.length; i++)
            this.currentFileWriter[fd].fd.write(this.currentFileWriter[fd].buffer[i]);
          this.currentFileWriter[fd].buffer = [];

          this.currentFileWriter[fd].fd.close();
          delete this.currentFileWriter[fd];

          {

            delete this.filesReceiving[fd];
            // var index = this.filesReceiving.indexOf(fd);
            // if (index != -1)
            //   this.filesReceiving.slice(index);
          }
        }
        else {
          // file data received, but we dont know anything about ths file.. 
          // file creatwWriteabe pending... 
          this.currentFileWriter[fd].xxx = true;
        }
      }
    },
    async openw(destDir, fd, fname, copyop, offset, fsize) {
      var startFolder = await this.localUserFS.getDirFromPath(destDir);
      var file = await this.localUserFS.createFolderAndFile(startFolder, fname);
      var recvFile = {
        fd: fd,
        name: fname,
        rpos: offset,
        fsize: fsize
      };
      this.filesReceiving[fd] = recvFile;

      //console.log("createWritable " + fd);
      if (this.currentFileWriter[fd] === undefined) {
        this.currentFileWriter[fd] = {
          fd: await file.createWritable(),
          buffer: [],
          xxx: false
        }
      }
      else {
        this.currentFileWriter[fd].fd = await file.createWritable();
      }

      if (copyop == "resume") {
        await this.currentFileWriter[fd].fd.seek(offset);
      }
      if (this.currentFileWriter[fd].xxx == true) {
        this.close(destDir, fd, fname);
        delete this.filesReceiving[fd];
        // var index = this.filesReceiving.indexOf(fd);
        // if (index != -1)
        //   this.filesReceiving.slice(index);
      }
    },

    async handleInfo(localDir, dir, files, copyop) {
      // check does dest folder exists
      var resFiles = [];
      var path = dir.split('/');
      var root = this.localDir;
      for (var i = 0; i < path.length - 1; i++) {
        root = await this.localUserFS.getFolder(root, path[i]);
        if (root == null)
          break;
      }

      // if dest folder exists, check file overwrite...
      if (root != null) {
        for (var j = 0; j < files.length; j++) {
          if (files[j].type == 2) // its a file
          {
            var fname = files[j].name;
            var localFile = await this.localUserFS.getFile(root, fname);
            var size = 0;
            if (localFile != null) size = localFile.size;
            var theFile = {
              name: fname,
              type: 2,
              size: size
            };
            resFiles.push(theFile);
          }
          else if (files[j].type == 1) {
            var theFile = {
              name: files[j].name,
              type: 1,
              size: 0
            };
            resFiles.push(theFile);
            // collect files rec...      
            var filesInFolder = [];
            var subFolder = await this.localUserFS.getFolder(root, theFile.name);
            //console.log(subFolder);
            await this.localUserFS.collectLocalFilesRec("", subFolder, filesInFolder);
            for (var k = 0; k < filesInFolder.length; k++) {
              var theFile = {
                name: filesInFolder[k].name,
                type: filesInFolder[k].type,
                size: filesInFolder[k].src != undefined ? filesInFolder[k].src.size : 0
              };
              resFiles.push(theFile);
            }
          }
        }
      }

      await UTILS.sendCommand(cdc, {
        cmd: "infoResult",
        params: {
          dir: dir,
          localDir: localDir,
          files: resFiles,
          copyop: copyop
        }
      });
    },

    async handleResume(localDir, dir, files) {
      // check does dest folder exists
      var path = dir.split('/');
      var root = this.localDir;
      for (var i = 0; i < path.length; i++) {
        root = await this.localUserFS.getFolder(root, path[i]);
        if (root == null)
          break;
      }
      // if dest folder exists, check file overwrite...
      if (root != null) {
        for (var j = 0; j < files.length; j++) {
          if (files[j].type == 2) // its a file
          {
            var parts = files[j].name.split('/');
            var froot = root;
            for (var k = 0; k < parts.length - 1; k++) {
              froot = await this.localUserFS.getFolder(froot, parts[k]);
              if (froot == null) // folder does not exists
                break;
            }
            if (froot != null) // folder exists.. check is there a file in 
            {
              var fname = parts[parts.length - 1];
              var localFile = await this.localUserFS.getFile(froot, fname);
              if (localFile != null)
                files[j]["size"] = localFile.size;
              else
                files[j]["size"] = 0;
            }
            else {
              files[j]["size"] = 0;
            }
          }
        }
      }
      else {
        for (var j = 0; j < files.length; j++)
          if (files[j].type == 2) files[j]["size"] = 0;
      }

      await UTILS.sendCommand(cdc, {
        cmd: "resumeCopy",
        params: {
          dir: dir,
          localDir: localDir,
          files: files
        }
      });
    },

    async refreshDir(dir) {
      var localDir = this.localUserFS.getCurrentPath();
      //console.log("dir = " + dir);
      //console.log("localDir = " + localDir);
      if (localDir == dir) {
        this.localUserItems = await this.localUserFS.collectFiles();
      }
    },

    async remoteUserDir(dir) {
      await this.sendRemoteFolder(false);
    },
    async remoteUserGoHome() {
      this.remoteUserFS.home();
      await this.sendRemoteFolder(true);
    },
    async remoteUserGoUp() {
      if (this.remoteUserFS.up())
        await this.sendRemoteFolder(true);
    },
    // eslint-disable-next-line
    async remoteUserCD(name, id) {
      if (await this.remoteUserFS.cd(name))
        await this.sendRemoteFolder(true);
    },
    async sendRemoteFolder(refresh) {
      if (cdc != null) {
        var files = await this.remoteUserFS.collectFiles();
        await UTILS.sendCommand(cdc, {
          cmd: "list",
          params: {
            dir: this.remoteUserFS.getCurrentPath(),
            files: files,
            refresh: refresh
          }
        });
      }
    },
    async onDCOpen() {
      //console.log("DC Open");
      this.connReady = true;
      if (this.localDir != null) {
        await this.sendRemoteFolder(true);
      }
      this.socket.close();
    },
    async onDCClose() {
      this.connReady = false;
      //console.log("DC Close");
      alert("dc close");
    },
    async onLocalSelect(f) {
      //console.log(f);
    },
    async onLocalDblClick(f) {
      //console.log("onLocalDblClick ");
      //console.log(f);
      if (f.type == 1) {
        await this.localUserFS.cd(f.h);
        this.localUserItems = await this.localUserFS.collectFiles();
        //console.log(this.localUserItems);
      }
    },
    onRemoteSelect(f) {
      //console.log(f);
    },
    async onRemoteDblClick(f) {
      //console.log("onRemoteDblClick ");
      //console.log(f);
      if (f.type == 1) {
        await UTILS.sendCommand(cdc, {
          cmd: "cd",
          params: {
            name: f.name,
            id: f.id
          }
        });
      }
    },
    async sendCopyFinished(destDir) {
      setTimeout(async () => {
        await UTILS.sendCommand(cdc, {
          cmd: "copyFinished",
          params: {
            dir: destDir,
          }
        });

        await UTILS.sendCommand(cdc, {
          cmd: "dir",
          params: {
            dir: this.remoteUserFS.getCurrentPath(),
          }
        });
      }, 2000);
    },
    async refreshLocalFolderView() {
      this.localUserItems = await this.localUserFS.collectFiles();
      if (this.connReady) {
        await UTILS.sendCommand(cdc, {
          cmd: "dir",
          params: {
            dir: this.remoteUserFS.getCurrentPath(),
          }
        });
      }
    },
    async onLocalShare() {
      try {
        this.localDir = await window.showDirectoryPicker();
        var status = await this.localDir.requestPermission({ mode: 'readwrite' });
        //console.log(status);
        if (status == "granted") {
          this.localUserFS.init(this.localDir);
          this.remoteUserFS.init(this.localDir);
          this.localUserItems = await this.localUserFS.collectFiles();
          if (this.connReady)
            await this.sendRemoteFolder();
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async onLocalGoUp() {
      if (this.localDir != null) {
        if (this.localUserFS.up()) {
          this.localUserItems = await this.localUserFS.collectFiles();
        }
      }
    },
    async onLocalGoHome() {
      if (this.localDir != null) {
        this.localUserFS.home();
        this.localUserItems = await this.localUserFS.collectFiles();
      }
    },
    async onLocalReload() {
      this.localUserItems = await this.localUserFS.collectFiles();
    },

    async GoUpOnRemoteSide() {
      if (cdc != null) {
        await UTILS.sendCommand(cdc, {
          cmd: "up",
          params: {}
        });
      }
    },
    async GoHomeOnRemoteSide() {
      if (cdc != null) {
        await UTILS.sendCommand(cdc, {
          cmd: "home",
          params: {}
        });
      }
    },

    async GoReloadRemoteSide() { 
      if (cdc) {
        await UTILS.sendCommand(cdc, {
          cmd: "dir",
          params: {
            dir: this.remoteUserFS.getCurrentPath(),
          }
        });
      }
    },

    async collectLocalUserSelectedFiles() {
      //collect files
      var filesToCopy = [];
      for (var i = 0; i < this.localUserItems.length; i++)
      {
        if (this.localUserItems[i].selected)
        {
          if (this.localUserItems[i].type == 2) // a file
          {
            var fe = {
              type: 2,
              src: this.localUserItems[i].h,
              name: this.localUserItems[i].name
            };
            filesToCopy.push(fe);
          }
          else
            await this.localUserFS.collectLocalFilesRec("", this.localUserItems[i].h, filesToCopy);
        }
      }

      return filesToCopy;
    },

    async collectRemoteUserSelectedFiles() {
      //collect files
      var filesToCopy = [];
      for (var i = 0; i < this.remoteUserItems.length; i++) {
        if (this.remoteUserItems[i].selected) {
          var fe = {
            type: this.remoteUserItems[i].type,
            name: this.remoteUserItems[i].name
          };
          filesToCopy.push(fe);
        }
      }

      return filesToCopy;
    },

    async uploadFiles(localDir, remoteDirName, filesToCopy, copyop) {
      var files = [];
      for (var i = 0; i < filesToCopy.length; i++)
        files.push({
          type: filesToCopy[i].type,
          name: filesToCopy[i].name,
          size: 0,
        });
      //console.log(files);    
      this.CopyToRemote(localDir, files, remoteDirName, copyop);
    },

    async requestFilesInfo(localDirName, remoteDirName, filesToCopy, copyop)
    {
      var files = [];
      for (var i = 0; i < filesToCopy.length; i++) {
        var f = {
          type: filesToCopy[i].type,
          name: filesToCopy[i].name
        };
        files.push(f);
      }

      await UTILS.sendCommand(cdc, {
        cmd: "info",
        params: {
          dir: remoteDirName,
          localDir: localDirName,
          files: files,
          copyop: copyop
        }
      });
    },

    async requestResume(localDirName, remoteDirName, filesToCopy)
    {
      var files = [];
      for (var i = 0; i < filesToCopy.length; i++)
      {
        var f = {
          type: filesToCopy[i].type,
          name: filesToCopy[i].name
        };
        files.push(f);
      }

      await UTILS.sendCommand(cdc, {
        cmd: "resume",
        params: {
          dir: remoteDirName,
          localDir: localDirName,
          files: files
        }
      });
    },

    async requestCopy(localDir, files, destDir, copyop)
    {
      await this.CopyToRemote(destDir, files, localDir, copyop);
    },

    async resumeCopy(localDir, files, destDir) { 
      await this.CopyToRemote(localDir, files, destDir, "resume");
    },

    async handleInfoResult(localDir, files, destDir, copyop) {
      // compare with local files and remove completed
      var lDir = await this.localUserFS.getDirFromPath(localDir);
      var filesToCopy = [];
      for (var i = 0; i < files.length; i++)
      {
        if (files[i].type == 2) {
          var localFile = await this.localUserFS.getFile(lDir, files[i].name);
          if (localFile != null) {
            if (copyop == "resume" & localFile.size < files[i].size) {
              files[i].size = localFile.size; // resume
              filesToCopy.push(files[i]);
            }
            else
            {
              files[i].size = 0; 
              filesToCopy.push(files[i]);
            }
          }
          else {
            files[i].size = 0; // start from begining
            filesToCopy.push(files[i]);
          }
        }
        else {
          var testDir = await this.localUserFS.getRelativeDirFromPath(lDir, files[i].name);
          if (testDir == null)
            filesToCopy.push(files[i]);
        }
      }

      // sada imamo listing fajlova za kopiranje... posalji na remote i cekaj fajlove...
      await UTILS.sendCommand(cdc, {
        cmd: "requestCopy",
        params: {
          dir: destDir,
          localDir: localDir,
          files: filesToCopy,
          copyop: copyop
        }
      });
    },

    async downloadFiles(localDir, dir, filesToCopy) {
      var files = [];
      for (var i = 0; i < filesToCopy.length; i++)
        files.push({
          type: filesToCopy[i].type,
          name: filesToCopy[i].dest,
          size: 0,
        });
      //console.log(files);

      await UTILS.sendCommand(cdc, {
        cmd: "requestCopy",
        params: {
          dir: dir,
          localDir: localDir,
          files: filesToCopy,
          copyop: "overwrite"
        }
      });
    },

    async CopyToRemote(localDir, files, destDir, copyop)
    {
      //console.log(`CopyToRemote COPY: ${localDir} to ${destDir}`);
      //console.log(files);
      
      for (var i = 0; i < files.length; i++)
      {
        var file = files[i];
        file["rpos"] = 0;
        file["fsize"] = 0;
        this.filesInProgress.push(file);
      }
      // localDir is path/a/b/c/

      var srcDir = await this.localUserFS.getDirFromPath(localDir);
      // console.log("srcDir ");
      // console.log(srcDir); 

      //var packetSize = 49152 - 3;
      var packetSize = 262144 - 3;
      //var itemIndex = 0;
      var currentRPos = 0;
      var currentFSize = 0;
      var localFile = null;
      var sendClose = false;

      fdc.bufferedAmountLowThreshold = 1024*1024;

      const nextItem = () => {     
        //console.log("nextItem...");
        this.fileFd++;
        delete this.filesInProgress.shift();
        if (this.filesInProgress.length == 0)
        {
          //console.log("nextItem SENT ALL!!!");
          fdc.onbufferedamountlow = null;
          this.sendCopyFinished(destDir);
        }
        // else
        //   console.log(this.filesInProgress[0]);
      }
      fdc.onbufferedamountlow = () => {
        send();
      };

      const send = async () => {
        //console.log("IN SEND...");
        //console.log("BUFFERED: " + fdc.bufferedAmount + " / " + fdc.bufferedAmountLowThreshold);

        while (true) {
          if (this.filesInProgress.length == 0)
            break;

          if (fdc.bufferedAmount > fdc.bufferedAmountLowThreshold)
            return;
            
          //console.log(this.filesInProgress[0]);
          if (this.filesInProgress[0].type == 1) // dir
          {
            var remoteDir = destDir + this.filesInProgress[0].name;
            //console.log("MKDIR " + remoteDir);
            await UTILS.sendCommand(fdc, {
              cmd: "mkdir",
              params: remoteDir
            });
            nextItem();
            continue;
          }
          else if (this.filesInProgress[0].type == 2) // file
          {
            //console.log("SEND FILE PART...");
            if (sendClose) {
              //console.log(">>> close");
              await UTILS.sendCommand(fdc, {
                cmd: "close",
                params: {
                  dir: destDir,
                  fname: this.filesInProgress[0].name,
                  fd: this.fileFd
                }
              });
              sendClose = false;
              localFile = null;
              nextItem();
              continue;
            }
            if (localFile == null) {
              localFile = await this.localUserFS.getFile(srcDir, this.filesInProgress[0].name);

              if (localFile != null)
              {
                currentFSize = localFile.size;
                currentRPos = copyop == "resume" ? this.filesInProgress[0].size : 0;

                await UTILS.sendCommand(fdc, {
                  cmd: "openw",
                  params: {
                    destDir: destDir,
                    fname: this.filesInProgress[0].name,
                    copyop: copyop,
                    offset: this.filesInProgress[0].size,
                    fd: this.fileFd,
                    fsize: localFile.size
                  }
                });
              }
              else
              {
                nextItem();
                continue;
              }
            }

            if (localFile != null)
            {
              if (currentRPos < currentFSize) {
                var partsize = currentFSize - currentRPos;
                if (partsize > (packetSize - 7))
                  partsize = packetSize - 7;

                var next_slice = currentRPos + partsize;
                var blob = localFile.slice(currentRPos, next_slice);
                var ab = await blob.arrayBuffer();

                UTILS.sendFilePart(fdc, this.fileFd, new Uint8Array(ab));
                currentRPos += partsize;
                this.filesInProgress[0].rpos = currentRPos;
                this.filesInProgress[0].fsize = currentFSize;
              }
              if (currentRPos >= currentFSize) {
                sendClose = true;
              }
            }
          }
        }
      }

      await send();
    },

    onUpload() { 
      this.$refs.copydlg.open(async (copyop) => { 
        //console.log("START COPY " + copyop);
        var filesToCopy = await this.collectLocalUserSelectedFiles();
        //console.log(filesToCopy);
        if (copyop == 'resume') {
          this.requestResume(this.localUserFS.getCurrentPath(), this.localUserRemoteDir, filesToCopy, copyop)
        }
        else
          this.uploadFiles(this.localUserFS.getCurrentPath(), this.localUserRemoteDir, filesToCopy, copyop);
      }, () => { 
        //console.log("CANCEL");
      } );
    },

    DownloadFromRemote() { 

      this.$refs.copydlg.open(async (copyop) => { 
        var filesToCopy = await this.collectRemoteUserSelectedFiles();
        //console.log(filesToCopy);
        if (copyop == 'resume') {
          //this.requestResume(this.localUserFS.getCurrentPath(), this.localUserRemoteDir, filesToCopy)
          // 1. collect selection on right pane -> done
          // 2. send to remote 
          this.requestFilesInfo(this.localUserFS.getCurrentPath(), this.localUserRemoteDir, filesToCopy, "resume");
          // 3. remote collect file size for all files, folders and subfolders -> info
          // 4. remote side send back file list -> infoResult
          // 5. compare file list with local content. update copy offset or remove completed file
          // 6. send list of files to copy
        }
        else {
          // 6. send list of files to copy (may include folders)
          this.requestFilesInfo(this.localUserFS.getCurrentPath(), this.localUserRemoteDir, filesToCopy, "overwrite");
        }
      }, () => {
        //console.log("CANCEL");
      });
    },

    formatDateFromTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString();
    },
    async createOffer()
    {
      console.log("in createOffer");
      lc = new RTCPeerConnection(iceServers);

      cdc = lc.createDataChannel("C_"+this.room_name, { ordered: true});
      fdc = lc.createDataChannel("F_" + this.room_name, { ordered: true });
      
      cdc.onmessage = e => UTILS.decode(e.data); 
      fdc.onmessage = e => UTILS.decode(e.data); 

      cdc.onopen = () =>  this.onDCOpen();

      lc.onicecandidate = (e) => {
        //console.log("New Ice Candidate!");
        //console.log(lc.localDescription);
        //console.log(e.candidate);
        if (e.candidate != null) this.socket.emit("ice", {room_name : this.room_name, ice:e.candidate});
      };
      lc.createOffer().then((o) => {
        // console.log("SDP OFFER (local description)");
        // console.log(o);
        lc.setLocalDescription(o)
        this.socket.emit("create_offer", {room_name : this.room_name, o});
      }).then(() =>  console.log("Set successfully!")).catch(e => console.error(e));
      return lc.localDescription;
    },

    async createAnswer(offer, ices)
    {
      // console.log("in createAnswer");
      // console.log("Offer when creating answer:!");
      // console.log(offer);
      lc = new RTCPeerConnection(iceServers);
      lc.onicecandidate = (e) => {
        // console.log("New Ice Candidate!");
        // console.log(e.candidate);
        if (e.candidate != null) this.socket.emit("ice", {room_name : this.room_name, ice:e.candidate});
      }
      lc.ondatachannel = e => {
        console.log("in rc.ondatachannel...");
        console.log(e);
        if (e.channel.label[0] == 'C')
        {
          cdc = e.channel
          cdc.onmessage = e => UTILS.decode(e.data); 
          cdc.onopen = () => this.onDCOpen();        
          cdc.onclose = () => this.onDCClose();
          lc.dc = cdc;
        }
        else {
          fdc = e.channel
          fdc.onmessage = e => UTILS.decode(e.data);           
        }
      }
      
      lc.setRemoteDescription(offer).then(() => {
        console.log("Offer set!");
      }).catch(e => console.error(e));

      lc.createAnswer().then((a) => {
        console.log("SDP ANSWER (local description)");
        console.log(a);
        lc.setLocalDescription(a);
        
        this.socket.emit("create_answer", {room_name : this.room_name, a});
      }).then(() => {
        console.log("answer created");
        for (var i=0; i<ices.length; i++)
        {
          console.log("Add ice candidate...");
          console.log(ices[i]);
          lc.addIceCandidate(ices[i]);
        }
      }).catch(e => console.error(e));
      return lc.localDescription;
    },

    onHelp() { 
      this.$refs.helpdlg.open();
    },

    onEnterRoom()
    {
      this.socket = socketio.connect();//
      //this.socket = socketio.connect("https://localhost:8443");//
      // , {
      //   transports: ["polling"] // use WebSocket first, if available
      // });

      this.socket.on('connect',async  () => {
        console.log("Connected to server!")
        this.socket.emit("create_room", {room_name : this.room_name});
        this.inRoom = !this.inRoom;
      });

      this.socket.on('ice', async (data) => {
        console.log("got ICE", data);
        lc.addIceCandidate(data);
      });

      this.socket.on('create_offer_ready', async (data) => {
        console.log("in create_offer_ready");
        console.log(data);
        // eslint-disable-next-line no-unused-vars
        const offer = await this.createOffer();
        //this.socket.emit("create_offer", {room_name : this.room_name, offer});
      })

      this.socket.on('create_answer_ready', async (data) => { // offer, ices
        console.log("in create_answer_ready");
        console.log(data);
        // eslint-disable-next-line no-unused-vars
        const answer = await this.createAnswer(data.offer, data.ices);
        //this.socket.emit("create_answer", {room_name : this.room_name, answer});
      })

      this.socket.on('create_answer_done', async (data) => {
        console.log("Got answer!");
        console.log(data.answer);
        await lc.setRemoteDescription(data.answer).then(() => console.log("Answer set!")).catch(e => console.error(e));
      });

      this.socket.on('disconnect', async () => {
        console.log("socketio disconnect...");
        if (this.connReady == false) {
          this.inRoom = !this.inRoom;
        }
      });
    },
    onSendData()
    {
      console.log("Am i ready?");
      if(lc !== null)
      {
        cdc.send(this.text_to_send_data);
      }
    },
    onSendJSON(){
      var test={
        a:10,
        b:20,
        c:"hello"
      };
      cdc.send(JSON.stringify(test));
    },
    async onSendFile(){
      console.log("send file!!!");
      console.log(this.$refs.file.files);
      var ff = this.$refs.file.files[0];
      var currentPos = 0;
      var slice_size = 32*1024;
      cdc.send(ff.name);
      
      const send = async () => {
        while (currentPos <= ff.size) {
          if (cdc.bufferedAmount > cdc.bufferedAmountLowThreshold) {
            cdc.onbufferedamountlow = () => {
              cdc.onbufferedamountlow = null;
              send();
            };
            return;
          }
          var next_slice = currentPos + slice_size;
          var blob = ff.slice(currentPos, next_slice);
          cdc.send(await blob.arrayBuffer());
          currentPos += slice_size;
         }
         cdc.send("end.");
      };
      send();      
    },

    async handleDirectoryEntry( dirHandle, out ) {
      var dirname = dirHandle.name;
      for await (const entry of dirHandle.values()) {
        if (entry.kind === "file"){
          const file = await entry.getFile();
          out[ dirname+"/"+file.name ] = file;
        }
        if (entry.kind === "directory") {
          //const newOut = out[ entry.name ] = {};
          await this.handleDirectoryEntry( entry, out );
        }
      }
    },

    async testDir()
    {
      const dir = await window.showDirectoryPicker();
      var status = await dir.requestPermission({mode: 'readwrite'});
      console.log(status);
    /*  
      var allfiles = {};
      await this.handleDirectoryEntry(dir, allfiles);

      for (var k in allfiles)
      {
        console.log(allfiles[k]);
        if (allfiles[k].name=="ndiscan.cpp")
        {
          var reader = new FileReader();
          reader.readAsText(allfiles[k], "UTF-8");
          reader.onload = function (evt) {
            console.log(evt.target.result);
            //document.getElementById("fileContents").innerHTML = evt.target.result;
          }
          reader.onerror = function (evt) {
            console.log(evt);
            //document.getElementById("fileContents").innerHTML = "error reading file";
          } 
        }
      }
    */
      try {
        console.log("pokusavam upis u chrome-file.txt");
        //const fileHandle = await dir.getFileHandle('chrome-file.txt', { create: true });
        const fileHandle = await dir.getFileHandle('chrome-file.txt', { create: true });
        console.log("fileHandle =");
        console.log(fileHandle);
        if (fileHandle != null)
        {
          const writableStream = await fileHandle.createWritable();
          await writableStream.write("Hello world");
          await writableStream.close();
        }
      }
      catch (e)
      {
        console.error(e);
      }
    }
  }
}
</script>

<style scoped>
.progresspanel{
  display: block;
    overflow-y: auto;
    overflow-x: none;
    height: 180px;
    border: 2px solid gray;
}
.fillvertical {
  display: block;
  overflow-y: auto;
  overflow-x: none;
  height: -webkit-calc(100vh - 300px);
  height: -moz-calc(100vh - 300px);
  height: calc(100vh - 300px);
  border: 2px solid gray;
}
.mycont {
  max-width: 90vw;
}
</style>