import 'mdb-vue-ui-kit/css/mdb.min.css';
import { createApp } from 'vue'
import App from './App.vue'

/* import the fontawesome core */
//import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import brands from '@fortawesome/free-brands-svg-icons'
//import solid from '@fortawesome/free-solid-svg-icons'

/* import specific icons */
// import {
//     faArrowRotate,
//     faUp,
//     faHouse,
//     faChevronsRight,
//     faChevronsLeft
// } from '@fortawesome/free-solid-svg-icons'

// <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
// <i class="fa-sharp fa-solid fa-up"></i>
// <i class="fa-sharp fa-solid fa-house"></i>
// <i class="fa-solid fa-chevrons-right"></i>

/* add icons to the library */
// library.add(brands, solid)
// library.add(faArrowRotate);
// library.add(faUp);
// library.add(faHouse);
// library.add(faChevronsRight);
// library.add(faChevronsLeft);

createApp(App)
    // .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
