<template>
  <MDBModal v-model="visible" size="sm">
    <MDBModalHeader>
        <MDBModalTitle id="exampleModalLabel"> Copy files... </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>
        <MDBRow class="text-start">
            <MDBCol>
                <MDBRadio label="Overwrite" name="copyop" v-model="copyop" value="overwrite" />
                <MDBRadio label="Resume" name="copyop" v-model="copyop" value="resume" />
            </MDBCol>
        </MDBRow>

    </MDBModalBody>
    <MDBModalFooter>
        <MDBBtn color="secondary" @click="onCancel">Canced</MDBBtn>
        <MDBBtn color="primary" @click="onOK">Copy</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
</template>

<script>
import {
    MDBModal,
    MDBRow,
    MDBCol,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBRadio
} from "mdb-vue-ui-kit";

export default {
    name: "CopyDlg",
    components: {
        MDBModal,
        MDBRow,
        MDBCol,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
        MDBBtn, 
        MDBRadio
    },
    data() {
        return {
            visible: false,
            copyop: "",
            cbOK: null,
            cbCancel:null
        }
    },
    mounted() { },
    methods: {
        open(cbOk, cbCancel) {
            this.cbCancel = cbCancel;
            this.cbOK = cbOk;
            this.copyop = "";
            this.visible = true;
        },
        onOK() { 
            this.visible = false;
            if (this.copyop == "")
                this.onCancel();
            else if (this.cbOK != null)
                this.cbOK(this.copyop);
        },
        onCancel() { 
            this.visible = false;
            if (this.cbCancel != null)
                this.cbCancel();
        },
    }
}
</script>

<style>

</style>