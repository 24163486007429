<template>
    <table class="noselect">
        <thead>
            <tr>
                <th style="width:100px;">#</th>
                <th class="text-start" style="width:70%;">Name</th>
                <th style="width:10%;">Type</th>
                <th class="text-end" style="width:10%;">Size</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="f in items" :key="f.id" @dblclick="onDblClick(f)">
                <td><MDBCheckbox v-model="f.selected" @change="onChecked(f)" /></td>
                <td class="text-start"><span>{{f.name}}</span></td>
                <td>{{getType(f.type)}}</td>
                <td class="text-end">{{getSize(f.size, f.type)}}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>

import { MDBCheckbox } from "mdb-vue-ui-kit";

const GIGABYTE = 1000 * 1000 * 1000;
const MEGABYTE = 1000 * 1000;
const KILOBYTE = 1000;

export default {
    name: "FileTable",
    components: {
        MDBCheckbox
    },
    props: {
        items:Array
    },
    data() { 
        return {
            columns: ["#", "Name", "Type", "Size"],
        }
    },
    mounted() { 

    },
    methods: {
        onChecked(item) { 
            //console.log(item);
            this.$emit("select", item);
        },
        onDblClick(f)
        {
            console.log("dblclick");
            console.log(f);
            this.$emit("dblclick", f);
        },
        getType(t)
        {
            if (t == 2) return "FILE";
            else return "DIR";
        },
        getSize(s, t)
        {
            if (t == 1) return "";
            if (s > GIGABYTE) return (s / GIGABYTE).toFixed(2) + " GB";
            else if (s > MEGABYTE) return (s / MEGABYTE).toFixed(2) + " MB";
            else if (s > KILOBYTE) return (s / KILOBYTE).toFixed(2) + " KB";
            else return s.toString();
        }
    }
}
</script>

<style scoped>
.noselect {
    cursor: default;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

tbody > tr:hover {
    background-color: rgb(80, 135, 255);
    color: white;
}
</style>