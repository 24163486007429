<template>
    <MDBRow class="text-start">
        <MDBCol>
            <MDBBtnGroup>
                <MDBBtn v-if="local==true" class="btn btn-success" @click="onShare">Share...</MDBBtn>
                <MDBBtn class="btn btn-primary" @click="onUp">UP</MDBBtn>
                <MDBBtn class="btn btn-primary" @click="onHome">HOME</MDBBtn>
                <MDBBtn class="btn btn-primary" @click="onReload">Reload</MDBBtn>
            </MDBBtnGroup>
            <MDBBtn v-if="local==true" class="btn btn-success" @click="onUpload"> &gt;&gt; </MDBBtn>
            <MDBBtn v-else class="btn btn-success" @click="onDownload"> &lt;&lt; </MDBBtn>
        </MDBCol>
    </MDBRow>
</template>

<script>
import { MDBRow, MDBCol, MDBBtn, MDBBtnGroup, MDBIcon } from "mdb-vue-ui-kit";


export default {
    name: "FileToolbar",
    props: {
        local: {
            type: Boolean,
            default: false
        },
    },
    components: {
        MDBRow, MDBCol, MDBBtn, MDBBtnGroup, MDBIcon
    },
    methods: {
        onShare() {
            this.$emit("share", {});
        },
        onUp() {
            this.$emit("goUp", {});
        },
        onHome() {
            this.$emit("goHome", {});
        },
        onReload() { 
            this.$emit("goReload", {});
        },
        onUpload() {
            this.$emit("upload", {});
        },
        onDownload() {
            this.$emit("download", {});
        },
    }
}
</script>

<style scoped>
    @import('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css')
</style>