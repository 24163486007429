<template>
    <table class="noselect">
        <thead>
            <tr>
                <th style="width:100px;">#</th>
                <th class="text-start" style="width:70%;">Name</th>
                <th class="text-end" style="width:20%;">Progress</th>
                <th class="text-end" style="width:150px;"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="f in items" :key="f.fd">
                <td>{{f.fd}}</td>
                <td class="text-start"><span>{{ f.name }}</span></td>
                <td class="text-end"><progress id="file" :value="getProgress(f)" max="100"> {{ getProgress(f) + "%"}} </progress></td>
                <td class="text-end">{{ getProgress(f) + "%"}}</td>
            </tr>
            <tr v-for="f in ritems" :key="f.fd">
                <td>{{f.fd}}</td>
                <td class="text-start"><span>{{ f.name }}</span></td>
                <td class="text-end"><progress id="file" :value="getProgress(f)" max="100"> {{ getProgress(f) + "%"}} </progress>
                </td>
                <td class="text-end">{{ getProgress(f) + "%"}}</td>
            </tr>
        </tbody>
    </table>  
</template>

<script>

import { MDBCheckbox } from "mdb-vue-ui-kit";


export default {
    name: "ProgressTable",
    components: {
        
    },
    props: {
        items: Array,        
        ritems: Object
    },
    data() {
        return {

        }
    },
    mounted() { },
    methods: {
        getProgress(f)
        {
            if (f.hasOwnProperty("rpos") && f.hasOwnProperty("fsize")) {
                if (f.fsize > 0) {
                    var val = (100 * f.rpos) / f.fsize;
                    // val = Math.round(val * 100);
                    // val = val / 100;
                    return val.toFixed(2);
                }
            }

            return 0;
        }
        
    }

}
</script>

<style scoped>
.noselect {
    cursor: default;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

tbody>tr:hover {
    background-color: rgb(80, 135, 255);
    color: white;
}
</style>