<template>
    <MDBModal v-model="visible" size="xl">
        <MDBModalHeader>
            <MDBModalTitle id="exampleModalLabel"> Very simple file copy over internet. </MDBModalTitle>
        </MDBModalHeader>
    <MDBModalBody class="text-start">
        <p>If you use Firefox, sorry, its not going to work. Please try with Chomium based browser. </p>
        <p>Choose room name. You cant use same room name twice (be creative until I fix this bug) </p>
        <p>Share your folder. You cant share Desktop, Documents or Downloads. This is built-in Chrome safety measures. </p>
        <p>Wait til other party connect to the same room and share its folder</p>
        <p>Select files and folder you want to Send on the Left pane</p>
        <p>Select files and folderyou want to Download on the Right pane</p>
        <p>Copy files using [&gt;&gt;] or [&lt;&lt;] buttons </p>
        <p>Data is encrypted and sent directly to other member in the room. There is no intermediate server.</p>
        <p>Dont close tab. File transfer will be interrupted.</p>
        <p>Room access is not (yet) password protected. Room accept only two members.</p>
    </MDBModalBody>
        <MDBModalFooter>
            <span style="float:right;">v0.6a</span>
        </MDBModalFooter>
  </MDBModal>
</template>

<script>
import {
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,

} from "mdb-vue-ui-kit";

export default {
    name: "HelpDlg",
    components: {
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        open() {
            this.visible = true;            
        }
    }
}
</script>

<style>

</style>