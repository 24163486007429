/* eslint-disable */

export default {
    cmdcb: null,
    FS: null,
    packetSize: 16384 - 3,
    recvBuffer: new Uint8Array(1024 * 1024),
    recvWPos: 0,
    enc: new TextEncoder(),
    dec: new TextDecoder(),
    init(cmdHandler, fileHandler) {
        this.cmdcb = cmdHandler;
        this.FS = fileHandler;
    },
    handleCommand: function (O) {
        //console.log(O);
        this.cmdcb(O["cmd"], O["params"]);
        // if (O["cmd"] == "openw") {
        //     var rfd = O.params["fd"];
        //     var rfname = O.params["fname"];
        //     FS.openw(rfd, "./storage/" + rfname);
        // }
        // else if (O["cmd"] == "close") {
        //     var rfd = O.params["fd"];
        //     FS.close(rfd);
        // }
    },
    handleFileData: function (fd, data) {
        this.FS(fd, data);
    },
    concat: function (buffers) {
        var size = 0;
        for (var i = 0; i < buffers.length; i++)
            size += buffers[i].length;
        var tmp = new Uint8Array(size);
        var pos = 0;
        for (var i = 0; i < buffers.length; i++) {
            tmp.set(buffers[i], pos);
            pos += buffers[i].length;
        }
        return tmp;// String.fromCharCode.apply(null, tmp);
    },
    strBuff: [],
    __decodeInt: function (packet, pktType) {
        if (pktType == 1) {
            var zpos = packet.indexOf(0);
            if (zpos != -1) {
                var p0 = packet.slice(0, zpos);
                var p1 = packet.slice(zpos + 1);
                this.strBuff.push(p0);
                var completeU8A = this.concat(this.strBuff);
                //console.log(completeU8A);
                var completeString = this.dec.decode(completeU8A);
                var O = JSON.parse(completeString);
                this.handleCommand(O);
                this.strBuff = [];
                if (p1.length > 0)
                    this.strBuff.push(p1);
            }
            else {
                this.strBuff.push(packet);
            }
        }
        else if (pktType == 2) {
            var rfd = (packet[0] << 24) + (packet[1] << 16) + (packet[2] << 8) + packet[3];
            var filedata = packet.slice(4);
            this.handleFileData(rfd, filedata);
        }
    },
    decode: function (dd) {
        var d = new Uint8Array(dd);
        // add data
        if (this.recvWPos + d.length < this.recvBuffer.length) {
            this.recvBuffer.set(d, this.recvWPos);
            this.recvWPos += d.length;
        }
        else {
            var newBuff = new Uint8Array(this.recvBuffer.length + d.length);
            newBuff.set(this.recvBuffer);
            this.recvBuffer = newBuff;
            this.recvBuffer.set(d, this.recvWPos);
            this.recvWPos += d.length;
        }

        // try to parse
        var readPos = 0;
        while (this.recvWPos - readPos > 5) {
            var u8 = new Uint8Array(this.recvBuffer);
            var pktSize = (u8[readPos] << 24) + (u8[readPos + 1] << 16) + (u8[readPos + 2] << 8) + u8[readPos + 3];
            var pktType = u8[readPos + 4];
            if (pktSize + 3 <= this.recvWPos) {
                // imamo ceo paket
                var packet = u8.slice(readPos + 5, readPos + pktSize + 5);
                // console.log(`__decodeInt T: ${pktType}, S: ${pktSize}`);
                this.__decodeInt(packet, pktType, pktSize);
                readPos += pktSize + 5;
            }
            else break;
        }

        if (readPos > 0) {
            // now move everything to beginning of buffer
            var newBuff2 = new Uint8Array(1024 * 1024);
            newBuff2.set(this.recvBuffer.slice(readPos, this.recvWPos));
            this.recvBuffer = newBuff2;
            this.recvWPos = 0;
        }
    },
    sendCommand: async function (dc, d) {
        if (dc == null) {
            console.log("DATA CHANNEL NULL");
            return;
        }

        var str = JSON.stringify(d) + "\0";
        var rpos = 0;
        var strBuff = this.enc.encode(str);// Buffer.from(str);
        var len = strBuff.length;

        const send = async () => {
            while (rpos < len) {
                if (dc.bufferedAmount > dc.bufferedAmountLowThreshold) {
                    dc.onbufferedamountlow = () => {
                        dc.onbufferedamountlow = null;
                        send();
                    };
                    return;
                }

                var partsize = len - rpos;
                if (partsize > this.packetSize)
                    partsize = this.packetSize;

                var pkt = new Uint8Array(partsize + 5);
                pkt[0] = (partsize >> 24) & 0xff;
                pkt[1] = (partsize >> 16) & 0xff;
                pkt[2] = (partsize >> 8) & 0xff;
                pkt[3] = partsize & 0xff;
                pkt[4] = 1;
                pkt.set(strBuff.subarray(rpos, rpos + partsize), 5);
                dc.send(pkt);

                rpos += partsize;
            }
        };
        await send();
    },

    sendFilePart: async function (dc, fd, u8data) {
        //        console.log("sending file part... " + u8data.byteLength);
        var partsize = u8data.byteLength;
        var datasize = partsize + 4;
        var pkt = new Uint8Array(datasize + 5);
        //        console.log("sending file part... " + datasize);
        pkt[0] = (datasize >> 24) & 0xff;
        pkt[1] = (datasize >> 16) & 0xff;
        pkt[2] = (datasize >> 8) & 0xff;
        pkt[3] = datasize & 0xff;
        pkt[4] = 2;
        pkt[5] = (fd >> 24) & 0xff;
        pkt[6] = (fd >> 16) & 0xff;
        pkt[7] = (fd >> 8) & 0xff;
        pkt[8] = fd & 0xff;
        pkt.set(u8data, 9);
        //console.log(pkt);
        //decode(pkt);
        dc.send(pkt);
    },

    sendFile: async function (dc, fd, file, destDir, destname, copyop, offset) {
        console.log(file);
        console.log(copyop);

        var fsize = file.size;
        var rpos = 0;

        if (copyop == 'resume')
            rpos = offset;

        console.log(">>> openw");
        await this.sendCommand(dc, {
            cmd: "openw",
            params: {
                destDir: destDir,
                fname: destname,
                copyop: copyop,
                offset: offset,
                fd: fd
            }
        });

        const send = async () => {
            while (rpos < fsize) {
                if (dc.bufferedAmount > dc.bufferedAmountLowThreshold) {
                    dc.onbufferedamountlow = () => {
                        dc.onbufferedamountlow = null;
                        send();
                    };
                }

                var partsize = fsize - rpos;
                console.log("partsize = " + partsize);
                if (partsize > (this.packetSize - 7))
                    partsize = this.packetSize - 7;

                var next_slice = rpos + partsize;
                console.log(`slice = ${rpos} -> ${next_slice}`);
                var blob = file.slice(rpos, next_slice);
                var ab = await blob.arrayBuffer();

                console.log("BLOB");
                console.log(blob);
                console.log(ab);

                this.sendFilePart(dc, fd, new Uint8Array(ab));
                rpos += this.packetSize;
            }
            console.log(">>> close");
            await this.sendCommand(dc, {
                cmd: "close",
                params: {
                    dir: destDir,
                    fname: file.name,
                    fd: fd
                }
            });
        };
        console.log("await send()");
        await send();
    },
}